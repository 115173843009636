<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="regulFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="regulFlag"
            label="규제물질 대상여부"
            v-model="searchParam.regulFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="화학물질 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="mdmChemId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chemBasePop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          isDetail: false,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        regulFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'chem',
            field: 'chem',
            label: '화학물질명',
            child: [
              {
                name: 'chemNmEn',
                field: 'chemNmEn',
                label: '영문',
                align: 'center',
                sortable: true,
                style: 'width: 120px',
                type: 'link'
              },
              {
                name: 'chemNmKr',
                field: 'chemNmKr',
                label: '국문',
                align: 'center',
                sortable: true,
                style: 'width: 120px',
              },
            ]
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            sortable: true,
            style: 'width: 80px',
          },
          {
            name: 'molecularFormula',
            field: 'molecularFormula',
            label: '화학식',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'regulFlag',
            field: 'regulFlag',
            label: '규제물질<br/>대상여부',
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      regulFlagItems: [
        { code: 'Y', codeName: '규제O' },
        { code: 'N', codeName: '규제X' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.base.chemBase.list.url
      if (this.popupParam.isDetail) {
        this.listUrl = selectConfig.chm.base.chemBase.detailList.url
      }
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '화학물질 상세';
      this.popupOptions.param = {
        mdmChemId: row ? row.mdmChemId : '',
      };
      this.popupOptions.target = () => import(`${'./chemBaseDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
