var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.regulFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "regulFlag",
                    label: "규제물질 대상여부",
                  },
                  model: {
                    value: _vm.searchParam.regulFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "regulFlag", $$v)
                    },
                    expression: "searchParam.regulFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "화학물질 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            isExcelDown: false,
            isFullScreen: false,
            columnSetting: false,
            selection: _vm.popupParam.type,
            rowKey: "mdmChemId",
          },
          on: { rowDblclick: _vm.rowDblclick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLSELECT", icon: "check" },
                        on: { btnClicked: _vm.select },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }